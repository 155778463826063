<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="uc_con">
      <div class="uc_title d_f ali_c flex_1">
        <div class="uc_green"></div>
        <div>修改密码</div>
      </div>
      <div class="form_out">
        <el-form  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm">
          <el-form-item label="初始密码" prop="oldPassword">
            <el-input type="password" v-model="ruleForm.oldPassword"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input type="password" v-model="ruleForm.newPassword"></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="newPasswordSure">
            <el-input type="password" v-model="ruleForm.newPasswordSure"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="ch_btn d_f ali_c j_s">
              <div class="cp_btn" @click="save('ruleForm')">确认修改</div>
              <div class="cp_btn ac" @click="resetForm('ruleForm')">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "changePassword",
    data() {
      return {
        ruleForm:{
          newPassword:'',
          newPasswordSure:'',
          oldPassword:'',
        },
        rules:{
          oldPassword:[
            { required: true, message: '请输入初始密码', trigger: 'blur' },
          ],
          newPassword:[
            { required: true, message: '请输入新密码', trigger: 'blur' },
          ],
          newPasswordSure:[
            { required: true, message: '请输入确认新密码', trigger: 'blur' },
          ],
        }
      }
    },
    methods: {
      save(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(this.ruleForm.newPassword != this.ruleForm.newPasswordSure) return this.$message.error('新密码输入不一致');
            let send = {oldPassword:this.$md5(this.ruleForm.oldPassword),newPassword:this.$md5(this.ruleForm.newPassword)};
            this.$api.changePassword(send).then(res => {
              this.$message.success('修改成功');
              sessionStorage.removeItem('token');
              this.$refs[formName].resetFields();
              setTimeout(() => {
                this.$router.push('/login');
              },2000)
            })
          } else {
            return false
          }
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
      },
    }
  }
</script>

<style scoped lang="scss">
  .ch_btn div{
    &.ac{
      margin-left: 20px;
    }

    height: 70px;
    line-height: 70px;
    display: inline-block;
    padding: 0 20px;
    cursor: pointer;
  }
  .form_out{
    width: 540px;
    margin: 100px auto 300px auto;
  }
</style>
